<template>
  <section class="artists-i-section">
    <div class="startcover">
      <router-link to="artists">
        <div class="image-grid-container">
          <div v-for="(artist, index) in randomArtists" :key="index">
            <img :src="artist.image" :alt="artist.name" />
          </div>
          <div class="artists-overlay">
            <h3>Artists</h3>
          </div>
        </div>
        <!-- <div class="image-grid-container">
          <img src="../assets/artists/acortex.jpeg" alt="Alex Cortex" />
          <img src="../assets/artists/Altroy.png" alt="Altroy Jerome" />
          <img src="../assets/artists/dlms.jpg" alt="/DL/MS/" />
          <img
            src="../assets/artists/atom_tobias.jpeg"
            alt="Atom TM & Tobias."
          />
          <img src="../assets/artists/kemetrix.jpeg" alt="Kemetrix" />
          <img src="../assets/artists/tinman_.jpeg" alt="Tin Man" />
          <img src="../assets/artists/glow_.jpeg" alt="DJ Glow" />
          <img src="../assets/artists/lodig.jpeg" alt="Dan Lodig" />
          <div class="artists-overlay">
            <h3>Artists</h3>
          </div>
        </div> -->
      </router-link>
    </div>
  </section>
</template>

<script>
import { artists } from "@/assets/artistsData.js";
export default {
  name: "ArtistsComponent",
  data() {
    return {
      randomArtists: [],
    };
  },
  methods: {
    shuffle(array) {
      return array.sort(() => Math.random() - 0.5);
    },
    getRandomArtists() {
      const shuffledArtists = this.shuffle([...artists]);
      this.randomArtists = shuffledArtists.slice(0, 8);
    },
  },
  created() {
    this.getRandomArtists();
  },
};
</script>

<style scoped>
.artists-i-section {
  text-align: center;
  padding-top: 25px;
  margin-bottom: 250px;
  margin-top: 55px;
}
.artists-i-section h3 {
  color: rgb(203, 197, 197);
  font-size: 1rem;
  font-weight: 500;
  top: 25px;
  text-decoration: wavy;
  padding: 20px;
}

.image-grid-container {
  position: relative;
  width: 80%;
  margin: 40px auto;
  padding: 5px 5px 0 5px;
}
.image-grid-container img {
  width: 180px;
  border-radius: 5px;
  padding: 0 2px;
}

@media screen and (min-width: 650px) {
  .image-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    margin: 40px auto;
    padding: 5px;
    width: fit-content;
  }
  .image-grid-container img {
    width: 150px;
    height: auto;
    border-radius: 5px;
  }
  .image-grid-container .artists-overlay h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    margin: auto;
  }
}
@media screen and (min-width: 700px) {
  .image-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    margin: 40px auto;
    padding: 5px;
    width: fit-content;
  }
  .image-grid-container img {
    width: 180px;
    height: auto;
    border-radius: 5px;
  }
  .image-grid-container .artists-overlay h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    margin: auto;
  }
}
@media screen and (min-width: 790px) {
  .image-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    margin: 40px auto;
    padding: 5px;
    width: fit-content;
  }
  .image-grid-container img {
    width: 200px;
    height: auto;
    border-radius: 5px;
  }
  .image-grid-container .artists-overlay h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    margin: auto;
  }
}
@media screen and (min-width: 880px) {
  .image-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    margin: 40px auto;
    padding: 5px;
    width: fit-content;
  }
  .image-grid-container img {
    width: 220px;
    height: auto;
    border-radius: 5px;
  }
  .image-grid-container .artists-overlay h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    margin: auto;
  }
}
@media screen and (min-width: 950px) {
  .image-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    margin: 40px auto;
    padding: 5px;
    width: fit-content;
  }
  .image-grid-container img {
    width: 250px;
    height: auto;
    border-radius: 5px;
  }
  .image-grid-container .artists-overlay h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2.5rem;
    font-weight: 600;
    margin: auto;
  }
}
@media screen and (min-width: 1950px) {
  .image-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    margin: 40px auto;
    padding: 5px;
    width: fit-content;
  }
  .image-grid-container img {
    width: 300px;
    height: auto;
    border-radius: 5px;
  }
  .image-grid-container .artists-overlay h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 4.5rem;
    font-weight: 600;
    margin: auto;
  }
}
.image-grid-container .artists-overlay {
  position: absolute;
  top: 0;
  left: 2px;
  width: 100%;
  height: 100%;
  background: rgba(254, 102, 1, 0.15);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
  border-radius: 5px;
  box-sizing: border-box;
}
.image-grid-container:hover .artists-overlay {
  opacity: 1;
}
</style>
