export const artists = [
  {
    name: "Dan Lodig",
    image: require("../assets/artists/lodig.jpeg"),
    route: "danlodig",
  },
  {
    name: "/DL/MS/",
    image: require("../assets/artists/dlms.jpg"),
    route: "dlms",
  },
  {
    name: "Kemetrix",
    image: require("../assets/artists/kemetrix.jpeg"),
    route: "kemetrix",
  },
  {
    name: "Tin Man",
    image: require("../assets/artists/tinman_.jpeg"),
    route: "tinman",
  },
  {
    name: "Altroy Jerome",
    image: require("../assets/artists/Altroy.png"),
    route: "altroyjerome",
  },
  {
    name: "DJ Glow",
    image: require("../assets/artists/glow_.jpeg"),
    route: "djglow",
  },
  {
    name: "Alex Cortex",
    image: require("../assets/artists/acortex.jpeg"),
    route: "alexcortex",
  },
  {
    name: "Atom™ & Tobias.",
    image: require("../assets/artists/atom_tobias.jpeg"),
    route: "atomtmtobias",
  },
  {
    name: "Buffered Multiple",
    image: require("../assets/artists/buffered.jpeg"),
    route: "bufferedmultiple",
  },
  {
    name: "Elektro Guzzi",
    image: require("../assets/artists/elektro-guzzi-01.jpeg"),
    route: "elektroguzzi",
  },
  {
    name: "Reade Truth",
    image: require("../assets/artists/readetruth.jpeg"),
    route: "readetruth",
  },
  {
    name: "Pulsinger & Irl",
    image: require("../assets/artists/pulsirl.jpeg"),
    route: "pulsingerirl",
  },
  {
    name: "Phosphene",
    image: require("../assets/artists/phosphene.jpg"),
    route: "phosphene",
  },
  {
    name: "Bad Cop Bad Cop",
    image: require("../assets/artists/badcopbadcop.jpeg"),
    route: "badcopbadcop",
  },
  {
    name: "EPY",
    image: require("../assets/artists/epy.png"),
    route: "epy",
  },
  {
    name: "Anet K",
    image: require("../assets/artists/anetk.jpg"),
    route: "anetk",
  },
  {
    name: "Steven Patton",
    image: require("../assets/artists/spatton.jpeg"),
    route: "stevenpatton",
  },
  {
    name: "DJ Stingray 313",
    image: require("../assets/artists/stingray.jpg"),
    route: "djstingray",
  },
  {
    name: "Gerhard Potuznik",
    image: require("../assets/artists/potuznik.jpeg"),
    route: "gerhardpotuznik",
  },
  {
    name: "Brendon Moeller",
    image: require("../assets/artists/bmoeller.jpeg"),
    route: "brendonmoeller",
  },
  {
    name: "DJ Elin",
    image: require("../assets/artists/elin.jpeg"),
    route: "elin",
  },
  {
    name: "Egyptian Lover",
    image: require("../assets/artists/egyptianlover.jpeg"),
    route: "egyptianlover",
  },
  {
    name: "Lopazz",
    image: require("../assets/artists/lopazz.jpeg"),
    route: "lopazz",
  },
  {
    name: "Erdem Tunakan & Alpha Tracks",
    image: require("../assets/artists/alphakhan.jpeg"),
    route: "tunakanalphatracks",
  },
  {
    name: "Hi-Lo",
    image: require("../assets/artists/hilo.jpg"),
    route: "hilo",
  },
  {
    name: "Christopher Just",
    image: require("../assets/artists/just1.jpg"),
    route: "christopherjust",
  },
  {
    name: "Spesimen",
    image: require("../assets/artists/spesimen.jpg"),
    route: "spesimen",
  },
  {
    name: "Vedelius",
    image: require("../assets/artists/vedelius.jpg"),
    route: "Vedelius",
  },
  {
    name: "Richard Bartz",
    image: require("../assets/artists/horn.jpg"),
    route: "horn",
  },
];
