<template>
  <header id="header">
    <div class="daheader">
      <div class="logo">
        <router-link to="/">
          <img
            src="../assets/pomelo_logo_A_schwarz_fogra27.jpg"
            alt="pomelo-logo"
            height="100px"
          />
        </router-link>
      </div>
      <div class="pomelo-text">
        <nav class="desktop-nav">
          <ul>
            <li v-for="(item, index) in desktopMenuItems" :key="index">
              <router-link :to="item.link">{{ item.text }}</router-link>
            </li>
          </ul>
        </nav>
        <h1>
          POMELO
          <span class="small">[est. 1994] </span>
        </h1>
        <nav class="mobile-nav">
          <input
            type="checkbox"
            name="toggle-menu"
            id="toggle-menu"
            v-model="menuOpen"
          />
          <label for="toggle-menu">
            <svg width="36" height="27" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M24 16v2H0v-2h24zm0-8v2H0V8h24zm0-8v2H0V0h24z"
                fill="#feed01"
                fill-rule="evenodd"
              />
            </svg>
          </label>
          <menu id="dropdown-menu" v-show="menuOpen">
            <li v-for="(item, index) in menuItems" :key="index">
              <a @click="closeMenu" :href="item.link">{{ item.text }}</a>
            </li>
          </menu>
        </nav>
      </div>
    </div>
  </header>
</template>

<!-- <script>
export default {
  data() {
    return {
      menuOpen: false,
      menuItems: [
        { text: "Home", link: "/" },
        { text: "About", link: "/about" },
        { text: "Services", link: "/services" },
        { text: "Contact", link: "/contact" },
      ],
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    handleClickOutside(event) {
      // Check if the click was outside the menu
      const dropdownMenu = this.$refs.dropdownMenu;
      if (dropdownMenu && !dropdownMenu.contains(event.target)) {
        this.closeMenu();
      }
    },
  },
};
</script> -->
<script>
export default {
  data() {
    return {
      menuItems: [
        { text: "NEWS", link: "/news" },
        { text: "ARTISTS", link: "/artists" },
        { text: "CATALOGUE", link: "/catalogue" },
        { text: "ABOUT", link: "/about" },
        { text: "CONTACT", link: "/contact" },
        { text: "HOME", link: "/" },
      ],
      menuOpen: false,
    };
  },
  computed: {
    desktopMenuItems() {
      return this.menuItems.filter(
        (item) => item.text !== "HOME" && item.text !== "CONTACT"
      );
    },
  },
  methods: {
    closeMenu() {
      this.menuOpen = false;
    },
  },
};
</script>

<style scoped>
#header {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: rgb(33, 34, 3, 1);
  height: 130px;
}
.daheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--pomellow);
  font-size: medium;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
.daheader .logo {
  margin-right: 55px;
  flex-shrink: 0;
}
.daheader .logo img {
  margin-left: 25px;
  margin-top: 10px;
}
.pomelo-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: Blur, Arial, sans-serif;
  flex-grow: 1;
  min-width: 200px;
}
.pomelo-text h1 {
  margin-left: 35px;
  margin-top: 2rem;
  font-size: 1.9rem;
  opacity: 0.9;
  min-width: 280px;
  display: none;
}
.pomelo-text h1 .small {
  font-size: 0.45em;
  margin-top: 30px;
  margin-right: 20px;
  margin-bottom: 15px;
}
.desktop-nav {
  display: none;
  max-width: 600px;
  margin-left: auto;
}

.desktop-nav ul {
  display: flex;
  gap: 10px;
  list-style-type: none;
  flex-wrap: nowrap;
}

.desktop-nav ul li {
  font-size: 1.2rem;
  font-family: Roboto, sans-serif;
  padding: 10px 20px;
}

.desktop-nav ul li a {
  text-decoration: none;
  color: #feed01;
}
.desktop-nav ul li a:hover {
  color: #f9f4aa;
}

.mobile-nav {
  display: block !important;
  padding-top: 13px;
  padding-left: 50px;
  margin-top: 1rem;
  margin-right: 100px;
}
menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(82, 69, 6, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
}
menu li {
  margin: 1rem 0;
  background-color: rgba(254, 237, 1, 0.5);
  padding: 0.5em 2em;
  border: 1px solid #ccc;
  border-radius: 30px;
  color: #000;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}
menu li a {
  color: white;
  text-decoration: none;
}
menu li a:hover {
  color: yellow;
}
header {
  padding-inline: 2rem;
}
menu > li + li {
  margin-top: 1rem;
}
menu a {
  color: white;
  text-decoration: none;
}
#toggle-menu {
  all: unset;
}
#toggle-menu svg {
  color: var(--pomellow);
}
#toggle-menu:focus + label {
  outline: 2px solid rgb(48, 48, 49);
  outline-offset: 4px;
  border-radius: 5px;
}
#toggle-menu:checked ~ menu {
  right: 50px;
}
@media screen and (min-width: 280px) {
  menu li {
    font-size: 1.3rem;
  }
}
@media screen and (min-width: 320px) {
  menu li {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 360px) {
  menu li {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 740px) {
  .pomelo-text h1 {
    margin-left: 35px;
    margin-top: 2rem;
    font-size: 1.9rem;
    opacity: 0.9;
    min-width: 280px;
    display: block;
  }
  .pomelo-text h1 {
    margin-left: 5px;
  }
}
@media screen and (min-width: 950px) {
  .mobile-nav {
    display: none !important;
  }
  .desktop-nav {
    display: block;
    margin-top: 1rem;
    margin-right: 55px;
  }
}
@media screen and (max-width: 1099px) and (min-width: 768px) {
  .desktop-nav ul {
    gap: 10px;
  }

  .desktop-nav ul li {
    font-size: 1rem;
    padding: 5px 10px;
  }

  .pomelo-text {
    width: 45%;
  }

  .desktop-nav {
    width: 55%;
  }
}
</style>
