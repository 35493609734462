<template>
  <div class="newsss">
    <span><h2>NEWS</h2></span>
    <br />
    <button @click="toggleView">
      {{ showNewNews ? "prev" : "next" }}
    </button>
    <NewestNews v-if="!showNewNews" />
    <NewNews v-if="showNewNews" />
  </div>
</template>

<script>
import NewestNews from "@/components/NewestNews.vue";
import NewNews from "@/components/NewNews.vue";

export default {
  name: "NewsView",
  components: {
    NewestNews,
    NewNews,
  },
  data() {
    return {
      showNewNews: false,
    };
  },
  methods: {
    toggleView() {
      this.showNewNews = !this.showNewNews;
    },
  },
};
</script>

<style scoped>
.newsss {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  margin: 0 auto;
  padding-top: 25px;
  background-color: #fff;
  position: relative;
  margin-bottom: 160px;
}
.newsss span h2 {
  text-align: left;
  color: rgb(34, 33, 33);
  font-size: 1.5rem;
  margin-left: 100px;
  position: absolute;
  top: 25px;
  text-decoration: wavy;
  white-space: nowrap;
}
button {
  all: unset;
  position: absolute;
  top: 33px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: var(--pomellow);
  border: 1px solid #422800;
  border-radius: 20px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  font-size: 1rem;
  padding: 0 18px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 15px;
  z-index: 5;
}
button:hover {
  background-color: #fff;
}
button:active {
  background-color: #fff;
  box-shadow: #422800 2px 2px 0 0;
}
</style>
