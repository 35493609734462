import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NewsView from "@/views/NewsView.vue";
import NewNews from "@/components/NewNews.vue";
import NewestNews from "@/components/NewestNews.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/artists",
    name: "artists",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArtistsView.vue"),
  },
  {
    path: "/catalogue",
    name: "catalogue",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CatalogueView.vue"),
  },
  // {
  //   path: "/news",
  //   name: "news",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/NewsView.vue"),
  // },
  {
    path: "/news",
    name: "news",
    component: NewsView,
    redirect: "/news/newest", // Redirect to 'new' by default
    children: [
      {
        path: "new",
        component: NewNews,
      },
      {
        path: "newest",
        component: NewestNews,
      },
    ],
  },
  {
    path: "/imprint",
    name: "imprint",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ImprintView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyView.vue"),
  },
  {
    path: "/links",
    name: "links",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LinksView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  {
    path: "/danlodig",
    name: "danlodig",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/DanLodig.vue"),
  },
  {
    path: "/egyptianlover",
    name: "egyptianlover",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/EgyptianLover.vue"
      ),
  },
  {
    path: "/alexcortex",
    name: "alexcortex",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/AlexCortex.vue"),
  },
  {
    path: "/altroyjerome",
    name: "altroyjerome",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/AltroyJerome.vue"
      ),
  },
  {
    path: "/anetk",
    name: "anetk",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/AnetKirvelaite.vue"
      ),
  },
  {
    path: "/atomtmtobias",
    name: "atomtmtobias",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/AtomTobias.vue"),
  },
  {
    path: "/badcopbadcop",
    name: "badcopbadcop",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/BadCop.vue"),
  },
  {
    path: "/brendonmoeller",
    name: "brendonmoeller",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/BrendonMoeller.vue"
      ),
  },
  {
    path: "/bufferedmultiple",
    name: "bufferedmultiple",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/BufferedMultiple.vue"
      ),
  },
  {
    path: "/elin",
    name: "elin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/DjElin.vue"),
  },
  {
    path: "/djglow",
    name: "djglow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/DjGlow.vue"),
  },
  {
    path: "/djstingray",
    name: "djstingray",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/DjStingray.vue"),
  },
  {
    path: "/dlms",
    name: "dlms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/DlMs.vue"),
  },
  {
    path: "/elektroguzzi",
    name: "elektroguzzi",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/ElektroGuzzi.vue"
      ),
  },
  {
    path: "/epy",
    name: "epy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/EpyEpy.vue"),
  },
  {
    path: "/gerhardpotuznik",
    name: "gerhardpotuznik",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/GerhardPotuznik.vue"
      ),
  },
  {
    path: "/lopazz",
    name: "lopazz",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/LoPazz.vue"),
  },
  {
    path: "/phosphene",
    name: "phosphene",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/PhosPhene.vue"),
  },
  {
    path: "/pulsingerirl",
    name: "pulsingerirl",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/PulsingerIrl.vue"
      ),
  },
  {
    path: "/readetruth",
    name: "readetruth",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/ReadeTruth.vue"),
  },
  {
    path: "/kemetrix",
    name: "kemetrix",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/SoulwerkzDetroit.vue"
      ),
  },
  {
    path: "/stevenpatton",
    name: "stevenpatton",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/StevenPatton.vue"
      ),
  },
  {
    path: "/tinman",
    name: "tinman",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/TinMan.vue"),
  },
  {
    path: "/tunakanalphatracks",
    name: "tunakanalphatracks",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/TunakanAlpha.vue"
      ),
  },
  {
    path: "/hilo",
    name: "hilo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/HiLo.vue"),
  },
  {
    path: "/christopherjust",
    name: "christopherjust",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/ChristopherJust.vue"
      ),
  },
  {
    path: "/horn",
    name: "horn",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/artists/RichardBartz.vue"
      ),
  },
  {
    path: "/spesimen",
    name: "spesimen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/SpesiMen.vue"),
  },
  {
    path: "/vedelius",
    name: "vedelius",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/artists/VedeliUs.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
