<template>
  <section class="news">
    <div class="newsgrid">
      <div class="newrelease">
        <h3>also OUT NOW (12" & digital):</h3>
      </div>
      <div class="newreleasecover">
        <img src="../assets/pom49b.jpg" alt="POM49" />
      </div>
      <div class="presstext">
        <h4>Lok44 - Late Reaction</h4>
        <p class="rec-info">
          <i
            >From the straight-edge allure of 'Dime' to the enigmatic charm of
            'Stranger' and the jazzy sophistication of the title track, ending
            with the sub bass heavy electro funk tune 'Easy Virtue,' Lok44's
            'Late Reaction' is a captivating exploration of divergent forms of
            electric sweat music.</i
          >
        </p>
        <br />
      </div>
      <!-- <div class="players"> -->
      <iframe
        style="border: 0; width: 90%; height: 120px; background-color: #fff"
        class="player1"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1576654942&color=%23ff5500&inverse=false&auto_play=false&show_user=true"
      ></iframe>

      <iframe
        style="border: 0; width: 90%; height: 120px"
        class="player2"
        src="https://bandcamp.com/EmbeddedPlayer/album=423126638/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
        seamless
        ><a href="https://pomelo.bandcamp.com/album/late-reaction-pom49"
          >Late Reaction / POM49 by Lok44</a
        ></iframe
      >
      <!-- </div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: "NewNews",
};
</script>

<style scoped>
.news {
  padding-top: 25px;
  /* background-color: #fff; */
  position: relative;
  margin-bottom: 160px;
}
/* .news h2 {
  text-align: left;
  color: rgb(34, 33, 33);
  font-size: 1.5rem;
  margin-left: 100px;
  position: absolute;
  top: 25px;
  text-decoration: wavy;
} */
.newsgrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
}
.newrelease {
  height: 60px;
  padding-top: 15px;
  padding-bottom: 10px;
  text-align: left;
}
.newrelease h3 {
  color: #999;
  font-size: 1.1rem;
  padding-top: 20px;
}
.newreleasecover {
  grid-column: 2;
  grid-row: 2/ 4;
  background-color: #fff;
  padding-top: 0;
  margin-top: 0;
  margin-right: 20px;
  min-width: 200px;
  width: 270px;
}
.newreleasecover img {
  max-width: 100%;
  height: auto;
  margin-bottom: 14px;
}
.rec-info {
  font-size: 0.9rem;
  font-weight: normal;
  padding-top: 25px;
  padding-right: 30px;
  margin-bottom: 14px;
}
.presstext {
  width: 300px;
  text-align: justify;
  width: 90%;
  margin: auto;
}
.presstext h4 {
  font-size: 1rem;
  padding-top: 15px;
}
.presstext p {
  font-size: 0.9rem;
  font-weight: 400;
}
.player1,
.player2 {
  margin: auto;
  max-width: 300px;
  margin-bottom: 10px;
}

@media screen and (min-width: 560px) {
  .news {
    padding-top: 0;
    margin: auto;
  }
  .newsgrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    height: 100%;
  }
  .newrelease {
    height: 60px;
    padding-top: 15px;
    margin-bottom: 15px;
    text-align: left;
  }
  .newrelease h3 {
    color: #999;
    font-size: 1.1rem;
  }
  .newreleasecover {
    background-color: #fff;
    padding-top: 0;
    margin-top: 0;
    min-width: 200px;
    width: 350px;
  }
  .newreleasecover img {
    max-width: 100%;
    height: auto;
  }
  .rec-info {
    font-size: 0.9rem;
    font-weight: normal;
    padding-top: 25px;
  }
  .presstext h4 {
    font-size: 1.1rem;
    padding-top: 15px;
  }
  .presstext {
    padding-top: 10px;
    width: 400px;
    text-align: justify;
  }
  .player1,
  .player2 {
    margin: auto;
    max-width: 400px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 940px) {
  .newsgrid {
    display: grid;
    grid-template-rows: 0.1fr 0.5fr 0.5fr 0.2fr;
    grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
    gap: 0 25px;
    margin: 30px auto;
    height: 100%;
  }
  .newrelease {
    grid-column: 2;
    grid-row: 1 / 2;
    height: 60px;
    padding-top: 15px;
  }
  .newrelease h3 {
    color: #999;
    font-size: 1.2rem;
    text-align: left;
    margin-left: 39px;
  }
  .presstext {
    grid-column: 3;
    grid-row: 2 / 4;
    padding-right: 20px;
    padding-top: 10px;
    min-height: 310px;
  }
  .presstext h4 {
    font-size: 1.2rem;
    padding-top: 15px;
    margin-left: 20px;
  }
  .rec-info {
    font-size: 1rem;
    font-weight: normal;
    padding-top: 25px;
    padding-right: 30px;
    margin-left: 20px;
  }
  .presstext p {
    font-size: 1rem;
    font-weight: 400;
  }
  .newrelease h3 {
    color: #999;
    font-size: 1rem;
    text-align: left;
    margin-left: 39px;
  }
  .newreleasecover {
    grid-column: 2;
    grid-row: 2/ 4;
    background-color: #fff;
    padding-top: 0;
    margin-top: 0;
    margin-right: 20px;
    min-width: 200px;
    width: 250px;
  }
  .newreleasecover img {
    max-width: 100%;
    height: auto;
    margin-left: 40px;
    margin-bottom: 20px;
  }
  .player1 {
    grid-column: 2;
    grid-row: 4 / 5;
    margin: auto;
    min-width: 330px;
  }
  .player2 {
    grid-column: 3;
    grid-row: 4 / 5;
    min-width: 300px;
  }
}
@media screen and (min-width: 1000px) {
  .newsgrid {
    display: grid;
    grid-template-rows: 0.1fr 0.5fr 0.5fr 0.2fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 25px;
    margin: 25px auto;
    height: 100%;
  }
  .newrelease {
    grid-column: 2;
    grid-row: 1 / 2;
    height: 60px;
    padding-top: 15px;
  }
  .newrelease h3 {
    color: #999;
    font-size: 1rem;
    text-align: left;
    margin-left: 39px;
  }
  .presstext {
    grid-column: 3;
    grid-row: 2 / 4;
    padding-right: 20px;
    padding-top: 10px;
    min-height: 310px;
  }
  .presstext h4 {
    font-size: 1.4rem;
    padding-top: 15px;
  }
  .rec-info {
    font-size: 0.9rem;
    font-weight: normal;
    padding-top: 25px;
    padding-right: 30px;
  }
  .presstext p {
    font-size: 1rem;
    font-weight: 400;
  }
  .newrelease h3 {
    color: #999;
    font-size: 1.2rem;
    text-align: left;
    margin-left: 39px;
  }
  .newreleasecover {
    grid-column: 2;
    grid-row: 2/ 4;
    background-color: #fff;
    padding-top: 0;
    margin-top: 0;
    margin-right: 20px;
    min-width: 200px;
    width: 300px;
  }
  .newreleasecover img {
    max-width: 100%;
    height: auto;
    margin-left: 40px;
    margin-bottom: 20px;
  }
  .player1 {
    grid-column: 2;
    grid-row: 4 / 5;
    min-width: 350px;
  }
  .player2 {
    grid-column: 3;
    grid-row: 4 / 5;
    min-width: 350px;
  }
}
@media screen and (min-width: 1800px) {
  .newsgrid {
    display: grid;
    grid-template-rows: 0.1fr 0.5fr 0.5fr 0.2fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 25px;
    margin: 25px auto;
    height: 100%;
  }
  .newrelease {
    grid-column: 2;
    grid-row: 1 / 2;
    height: 60px;
    padding-top: 15px;
    margin-bottom: 40px;
  }
  .newrelease h3 {
    color: #999;
    font-size: 2rem;
    text-align: left;
    margin-left: 39px;
  }
  .presstext {
    grid-column: 3;
    grid-row: 2 / 4;
    padding-right: 20px;
    padding-top: 10px;
    min-height: 310px;
  }
  .presstext h4 {
    font-size: 1.8rem;
    padding-top: 15px;
    margin-bottom: 0;
  }
  .rec-info {
    font-size: 1.2rem;
    font-weight: normal;
    padding-top: 25px;
    padding-right: 30px;
  }
  .presstext p {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .newreleasecover {
    grid-column: 2;
    grid-row: 2/ 4;
    background-color: #fff;
    padding-top: 0;
    margin-top: 0;
    margin-right: 20px;
    min-width: 300px;
    width: 500px;
  }
  .newreleasecover img {
    max-width: 100%;
    height: auto;
    margin-left: 40px;
    margin-bottom: 20px;
  }
  .player1 {
    grid-column: 2;
    grid-row: 4 / 5;
    min-width: 350px;
  }
  .player2 {
    grid-column: 3;
    grid-row: 4 / 5;
    min-width: 350px;
  }
}
.outnow {
  font-size: 0.9em;
  color: black;
}
.releaseinfo {
  font-size: 0.7em;
  width: 70%;
  margin: 30px auto;
}
</style>
