<template>
  <section class="news-section" id="newsfeed">
    <h3>Out now:</h3>
    <div class="startcover">
      <div class="image-container">
        <img
          class="main-image"
          src="../assets/catalogue/POM51.jpg"
          alt="POM51"
        />
        <div class="news-overlay">
          <a
            href="https://pomelo.bandcamp.com/album/shoc-orridor-pom51"
            target="_blank"
            ><img
              class="hover-image"
              src="../assets/playbutton-w.png"
              alt="play"
          /></a>
        </div>
      </div>
    </div>
    <div class="text">
      <h4>Lo-x3 - Shoc_orridor</h4>
      <p>
        Lo-x3 unveils "Shoc_orridor”. While the A-Side pays homage to to the
        golden era of 90s techno with “Pima" and "Thereon”, the flipside treats
        us to a mesmerizing encounter with two distinct tracks. "CB-190301"
        weaves intricate soundscapes that challenge conventional norms and spark
        profound contemplation. In contrast, "Beth" unleashes a relentless
        baseline over broken beats, an unstoppable force ready to shake the very
        foundations of any sound system.
      </p>
      <br />
    </div>
  </section>
</template>

<script>
export default {
  name: "NewsComponent",
};
</script>

<style scoped>
.news-section {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 75px auto;
}
.news-section h3 {
  color: rgb(203, 197, 197);
  text-shadow: 0px 1px #999;
  font-size: 1.6rem;
  font-weight: 600;
  top: 25px;
  text-decoration: wavy;
  padding: 0;
  width: 60%;
}
.news-section .startcover .main-image {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
.image-container {
  position: relative;
  width: 200px;
  margin: 10px 0;
  text-align: center;
}
.image-container .news-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}
.image-container:hover .news-overlay {
  opacity: 1;
}
.image-container .hover-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 80%;
  opacity: 80%;
}
.news-section .text {
  width: 60%;
  margin-top: 5px;
  color: #333;
}
.news-section .text h4 {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 10px;
  color: #666;
  text-shadow: 0px 0.3px #999;
  z-index: 10;
  padding-bottom: 20px;
}
.news-section .text p {
  font-size: 1rem;
  font-weight: 400;
}
@media screen and (min-width: 400px) {
  .news-section h3 {
    color: rgb(203, 197, 197);
    text-shadow: 0px 1px #999;
    font-size: 1.8rem;
    font-weight: 600;
    top: 25px;
    text-decoration: wavy;
    padding: 0;
    width: 60%;
  }
  .image-container {
    position: relative;
    width: 300px;
    margin: 10px 0;
    text-align: center;
  }
  .news-section .text h4 {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 10px;
    color: #666;
    text-shadow: 0px 0.3px #999;
    z-index: 10;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 600px) {
  .image-container {
    position: relative;
    width: 400px;
    margin: 10px 0;
    text-align: center;
  }
  .news-section h3 {
    color: rgb(203, 197, 197);
    text-shadow: 0px 1px #999;
    font-size: 2rem;
    font-weight: 600;
    top: 25px;
    text-decoration: wavy;
    padding: 0;
    width: 60%;
  }
  .news-section .text h4 {
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 10px;
    color: #666;
    text-shadow: 0px 0.3px #999;
    z-index: 10;
    padding-bottom: 20px;
  }
}
</style>
