<template>
  <div class="header"><HeaderNavigation /> <router-view /></div>
  <div class="footer"><FooterNavigation /></div>
  <div v-if="showPopup" class="cookie-popup">
    <p>
      This website uses cookies to ensure you get the best experience on our
      website.
      <a href="https://pomelo.org/privacy" target="_blank">Learn more</a>.
      <button @click="acceptCookies">Got it!</button>
    </p>
  </div>
</template>
<script>
import HeaderNavigation from "@/components/HeaderNavigation.vue";
import FooterNavigation from "@/components/FooterNavigation.vue";

export default {
  components: {
    HeaderNavigation,
    FooterNavigation,
  },
  name: "App",
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    checkCookies() {
      const cookieConsent = localStorage.getItem("cookieConsent");
      console.log("Cookie consent:", cookieConsent);
      if (!cookieConsent) {
        this.showPopup = true;
      }
    },
    acceptCookies() {
      localStorage.setItem("cookieConsent", "true");
      console.log("Cookie consent set to true");
      this.showPopup = false;
    },
  },
  created() {
    this.checkCookies();
  },
};
</script>

<style>
:root {
  box-sizing: border-box;
  --blackish-color: #212203;
  --pomellow: #feed01;
}
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #fff;
  height: 100vh;
  font-size: 1.1em;
  min-height: 100vh;
}
@media screen and (min-width: 600px) {
  #app {
    font-size: 1.5em;
    min-height: calc(100vh - 100px);
  }
}
body,
#app {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: calc(100vh - 100px);
}
::selection {
  background-color: var(--pomellow);
  color: grey;
}
.header {
  position: relative;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
.cookie-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
  z-index: 1000;
  border-top: 1px solid #444;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
}
.cookie-popup p {
  margin: 0;
  font-size: 0.9rem;
}
.cookie-popup a {
  color: #f1c40f;
  text-decoration: underline;
}
.cookie-popup button {
  background: #f1c40f;
  border: none;
  color: #333;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
}
.cookie-popup button:hover {
  background: #e0b90f;
}
@font-face {
  font-family: "blurregular";
  src: url("assets/fonts/blur_regular-webfont.woff2") format("woff2"),
    url("assets/fonts/blur_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoblack";
  src: url("assets/fonts/roboto-black-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoblack_italic";
  src: url("assets/fonts/roboto-blackitalic-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold";
  src: url("assets/fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotobold_italic";
  src: url("assets/fonts/roboto-bolditalic-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoitalic";
  src: url("assets/fonts/roboto-italic-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotolight";
  src: url("assets/fonts/roboto-light-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotolight_italic";
  src: url("assets/fonts/roboto-lightitalic-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotomedium";
  src: url("assets/fonts/roboto-medium-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotomedium_italic";
  src: url("assets/fonts/roboto-mediumitalic-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotoregular";
  src: url("assets/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotothin";
  src: url("assets/fonts/roboto-thin-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "robotothin_italic";
  src: url("assets/fonts/roboto-thinitalic-webfont.woff2") format("woff2"),
    url("assets/fonts/roboto-thinitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
</style>
